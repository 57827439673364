<template>
    <NewsRenderer
        :key="newsUid"
        :is-news-opened-using-a-direct-link="true"
        @onNewsClosed="onNewsClosed"
    />
</template>

<script>
import NewsRenderer,
{ NewsRendererMode } from "./NewsRenderer";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import { RESET_CURRENT_NEWS } from "@web/store/news/mutations";
import store from "@web/store";

export default {
    name: "NewsRoute",
    components: {
        NewsRenderer,
    },
    props: {
        newsUid: String,
    },
    data() {
        return {
            previousRoute: null,
        };
    },
    mounted() {
        this.openNews(this.newsUid);
    },
    methods: {
        openNews(newsUid) {
            this.$modal.show("news-renderer", {
                newsUid,
                mode: NewsRendererMode.READ,
            });
        },
        onNewsClosed() {
            if (this.previousRoute.name === null) {
                this.$router.push({ name: "dashboard" });
            } else {
                this.$router.back();
            }
        },
    },
    beforeRouteEnter: (to, from, next) => next(vm => vm.previousRoute = from),
    beforeRouteLeave: (to, from, next) => {
        store.commit(NEWS_MODULE_NAME + RESET_CURRENT_NEWS);
        next();
    },
};
</script>

<style scoped>

</style>
